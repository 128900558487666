import React, { useEffect, useState } from 'react';

import WazeCard from '../CmsComponents/CustomFragment/OthersCustomFragments/WazeCard';

import {
  Container,
  CardContainer,
  Card,
  CardItem,
  CardItemImage,
  CardItemInformation,
} from './style';

function UnitContactCard({ units }) {
  const [storeUnits, setStoreUnits] = useState([]);
  const [isMobile, setIsMobile] = useState(false);

  const updateSize = () => {
    setIsMobile(window.innerWidth < 648);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateSize);
      setIsMobile(window.innerWidth < 648);
      return () => window.removeEventListener('resize', updateSize);
    }
  }, []);

  useEffect(() => {
    const formattedUnits = units.map(unit => {
      if (unit.addressAndInformation.length <= 0) {
        return unit;
      }

      const splittedAddressAndInformation = unit.addressAndInformation[0]
        .split('\n\n')
        .filter(e => e.trim() !== '');

      const formattedAddressAndInformation = splittedAddressAndInformation.map(
        item => {
          const splittedItem = item
            .split(/!\[([^)]+)\)/gi)
            .filter(e => e.trim() !== '');

          return {
            image: `![${splittedItem[0]})`,
            content: splittedItem[1].replace(/^[\n|\n\r]/gi, ''),
          };
        }
      );

      return {
        ...unit,
        formattedAddressAndInformation,
      };
    });

    setStoreUnits(formattedUnits);
  }, [units]);

  return (
    <Container>
      <CardContainer>
        {storeUnits.length > 0 &&
          storeUnits.map((unit, index) => (
            <Card key={index}>
              {unit.formattedAddressAndInformation?.map((unitItem, indexItem) =>
                !isMobile ? (
                  <CardItem key={indexItem}>
                    <CardItemImage>{unitItem.image}</CardItemImage>
                    <CardItemInformation>
                      {unitItem.content}
                    </CardItemInformation>
                  </CardItem>
                ) : (
                  indexItem === 0 && (
                    <WazeCard singletexts={unit.urlWaze}>
                      {[unitItem.image, unitItem.content].join('\n')}
                    </WazeCard>
                  )
                )
              )}
            </Card>
          ))}
      </CardContainer>
    </Container>
  );
}

export default UnitContactCard;
