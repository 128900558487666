import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { device } from 'components/device';

export const Container = styled.section`
  width: 100%;
`;

export const CardContainer = styled.section``;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  & + div {
    margin-top: 1.875rem;
    padding-top: 1.875rem;
    border-top: 1px solid #c6c8cc;

    @media ${device.mobile} {
      margin-top: 0;
    }
  }
`;

export const CardItem = styled.div`
  display: flex;
  align-items: center;
  gap: 1.25rem;
`;

export const CardItemImage = styled(ReactMarkdown)`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1.5rem;

  img {
    width: 100%;
    object-fit: contain;
  }
`;

export const CardItemInformation = styled(ReactMarkdown)`
  font-size: 1rem;
  line-height: 1.625rem;
  color: #231f20;

  @media ${device.tablet} {
    font-size: 0.875rem;
    line-height: 1.375rem;
  }

  em {
    display: block;
    font-style: normal;
  }

  p {
    display: block;
  }

  strong {
    font-weight: 600;
  }

  a {
    color: #45a7df;
  }
`;
