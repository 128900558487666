// Libs
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { Link } from 'gatsby';

// Components
import { device } from 'components/device';

export const Container = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 4.375rem 0;

  @media ${device.laptop} {
    flex-direction: column;
    padding: 1.875rem 0;
  }
`;

export const ContentTitle = styled.div`
  width: 100%;
  max-width: 15rem;

  @media ${device.laptop} {
    max-width: 20rem;
    margin-bottom: 1.875rem;
  }
`;

export const Title = styled(ReactMarkdown)`
  h2 {
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 1.875rem;
    color: #231f20;
    text-align: left;
    margin: 0;

    @media ${device.laptop} {
      font-size: 1.125rem;
      line-height: 1.375rem;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 35.625rem;
  background: #fff;
  border-radius: 0.5rem;

  @media ${device.laptop} {
    max-width: 100%;
  }
`;

export const ContentListIntro = styled(ReactMarkdown)`
  font-size: 1rem;
  line-height: 1.625rem;
  color: #231f20;
  margin-bottom: 3.125rem;

  @media ${device.laptop} {
    margin-bottom: 1.875rem;
  }

  @media ${device.mobile} {
    font-size: 0.875rem;
    line-height: 1.375rem;
  }

  a {
    color: #45a7df;
  }
`;

export const ContentList = styled.div``;

export const ListItem = styled.p`
  font-size: 1rem;
  line-height: 1.625rem;
  color: #231f20;
  display: block;

  & + a,
  & + p {
    margin-top: 1.25rem;
  }

  @media ${device.mobile} {
    font-size: 0.875rem;
    line-height: 1.375rem;
  }
`;

export const ListItemActive = styled(Link)`
  font-size: 1rem;
  line-height: 1.625rem;
  color: #45a7df;
  display: block;

  & + a,
  & + p {
    margin-top: 1.25rem;
  }

  @media ${device.mobile} {
    font-size: 0.875rem;
    line-height: 1.375rem;
  }
`;
