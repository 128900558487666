// Libs
import React from 'react';

// Components
import { formatPhraseToSlug } from 'utils/phraseToSlug';

// Styles
import {
  Container,
  ContentTitle,
  Title,
  Content,
  ContentListIntro,
  ContentList,
  ListItem,
  ListItemActive,
} from './style';

function PageListGroup({ markdownContent, pageList, disableItemBySlug = '' }) {
  const pageListData = markdownContent.split(/(##.*\n\n)/i).filter(el => el);
  const pageListTitle = pageListData[0];
  const pageListContent = pageListData[1];
  const pageListItems = pageList
    .map(item => {
      if (item.active) return { ...item, slug: formatPhraseToSlug(item.name) };
      return item;
    })
    .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() && 1) || -1);

  return (
    <Container>
      <ContentTitle>
        <Title children={pageListTitle} />
      </ContentTitle>
      <Content>
        <ContentListIntro>{pageListContent}</ContentListIntro>
        <ContentList>
          {pageListItems.map((item, index) => {
            if (item.active && item.slug !== disableItemBySlug) {
              return (
                <ListItemActive
                  key={index}
                  to={`/centro-de-imunizacao/${item.slug}`}
                >
                  {item.name}
                </ListItemActive>
              );
            }

            return <ListItem key={index}>{item.name}</ListItem>;
          })}
        </ContentList>
      </Content>
    </Container>
  );
}

export default PageListGroup;
