// Libs
import React from 'react';
import { graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import ReactMarkdown from 'react-markdown';
import GraphImg from 'graphcms-image';

// Components
import Layout from 'components/layout';
import { device } from 'components/device';
import SEO from 'components/seo';
import BreadCrumb from 'components/breadCrumb';
import UnitContactCard from 'components/UnitContactCard';
import PageListGroup from 'components/vaccines/pageListGroup';

// Styles
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1170px;
  margin: 1.875rem auto 4.375rem;

  @media ${device.laptop} {
    margin: 0 auto;
  }
`;

const ContentFeaturedMarketing = styled.section`
  min-height: 10rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${props =>
    props.borderBottom &&
    css`
      border-bottom: 1px solid #c6c8cc;
    `}

  @media ${device.laptop} {
    margin: 0 1.25rem;
  }

  @media ${device.tablet} {
    margin: 0 1.25rem;
    ${props =>
      props.borderBottomMobile
        ? css`
            border-bottom: 1px solid #c6c8cc;
          `
        : css`
            border-bottom: none;
          `}
  }

  @media ${device.mobile} {
    margin: 0 0 0 1.25rem;
  }
`;

const ContentFeaturedMarketingInformation = styled.div`
  width: 100%;
  min-width: 37.5rem;
  max-width: 37.5rem;

  h2 {
    font-size: 3.375rem;
    line-height: 3.75rem;
    color: #231f20;
    font-weight: 700;
    margin: 0;
  }

  @media ${device.tablet} {
    position: relative;
    min-width: unset;
    max-width: unset;

    h2 {
      font-size: 1.875rem;
      line-height: 2.25rem;
    }
  }

  @media ${device.mobile} {
    h2 {
      font-size: 1.5rem;
      line-height: 1.875rem;
    }
  }
`;

const ContentFeaturedMarketingImage = styled.div`
  width: 100%;

  @media ${device.tablet} {
    img {
      width: 10rem;
      height: auto;
    }
  }

  @media ${device.mobile} {
    img {
      left: 1.875rem !important;
    }
  }
`;

const Content = styled.section`
  @media ${device.laptop} {
    padding: 0 1.25rem;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  ${props =>
    props.borderBottom &&
    css`
      border-bottom: 1px solid #c6c8cc;
    `}
  ${props =>
    props.borderTop &&
    css`
      border-top: 1px solid #c6c8cc;
    `}
  padding: 4.375rem 0;

  div.w-100 {
    width: 100%;
  }

  @media ${device.laptop} {
    flex-direction: column;
    padding: 1.875rem 0;
  }
`;

const ContentContainerTitle = styled(ReactMarkdown)`
  width: 100%;
  min-width: 37.5rem;
  max-width: 37.5rem;

  h2,
  p {
    font-size: 1.5rem;
    line-height: 1.875rem;
    color: #231f20;
    font-weight: normal;
    width: 100%;
    max-width: ${props => props.h4MaxWidthDesktop};
    margin: 0;
  }

  @media ${device.laptop} {
    min-width: unset;
    max-width: unset;

    h2,
    p {
      max-width: unset;
      font-size: 1.125rem;
      line-height: 1.375rem;
      margin-bottom: 1.875rem;
    }
  }
`;

const ContentContainerInformation = styled(ReactMarkdown)`
  width: 100%;
  margin-bottom: ${props => props.marginBottom};

  ${props =>
    props.hiddenOnMobile &&
    css`
      @media ${device.tablet} {
        display: none;
      }
    `};

  p {
    font-size: 1rem;
    line-height: 1.625rem;
    color: #231f20;

    & + p {
      margin-top: 1rem;
    }
  }

  strong,
  b {
    font-weight: 600;
  }

  @media ${device.laptop} {
    font-size: 0.875rem;
    line-height: 1.375rem;
  }
`;

const Vaccine = ({
  data: {
    gcms: { site, vaccines, fragments },
  },
  pageContext,
}) => {
  const {
    name,
    seo,
    whatsItFor,
    composition,
    whoShouldTake,
    howMuchDose,
    contraindication,
    vaccineRouteOfApplication,
    units,
    slug,
    whereToTake,
  } = pageContext;
  const vaccineFragments = fragments?.[0].singletexts;
  const vaccinesList = site?.pages?.[0].fragments?.[0];

  const formattedSeo = seo && [{ seo }];

  const titleSeo = `${vaccineFragments?.[0]} ${name}`;
  const descriptionSEO = `${vaccineFragments?.[0]} ${name}`;
  const dataSeo = [
    {
      seo: {
        metaTitle: titleSeo,
        metaDescription: descriptionSEO,
      },
    },
  ];

  const formattedRouteOfApplication =
    vaccineRouteOfApplication &&
    vaccineRouteOfApplication
      .map(vaccineRoute => vaccineRoute.routeOfApplication)
      .filter(item => item);

  return (
    <Layout>
      <SEO dataSeo={formattedSeo || dataSeo} />
      <Container>
        <BreadCrumb
          marginBreadCrumb="unset"
          markdown={`1. [Home](/) › [Centro de Imunização](/centro-de-imunizacao/) › ${name}`}
        />
        <ContentFeaturedMarketing borderBottom borderBottomMobile={false}>
          <ContentFeaturedMarketingInformation>
            <h2>
              {vaccineFragments?.[0]}
              <br />
              {name}
            </h2>
          </ContentFeaturedMarketingInformation>
          <ContentFeaturedMarketingImage>
            <GraphImg
              image={fragments?.[0].assetpicker}
              className="ImgMarketing"
              alt="Destaque Marketing"
              fadeIn={true}
              withWebp={true}
            />
          </ContentFeaturedMarketingImage>
        </ContentFeaturedMarketing>
        <Content>
          <ContentContainer borderBottom>
            <ContentContainerTitle>
              {vaccineFragments?.[1]}
            </ContentContainerTitle>
            <div className="w-100">
              <ContentContainerInformation marginBottom="3.125rem">
                {whereToTake}
              </ContentContainerInformation>
            </div>
          </ContentContainer>
          <ContentContainer borderBottom>
            <ContentContainerTitle h4MaxWidthDesktop="6.375rem">
              {vaccineFragments?.[2]}
            </ContentContainerTitle>
            <ContentContainerInformation>
              {whatsItFor}
            </ContentContainerInformation>
          </ContentContainer>
          <ContentContainer borderBottom>
            <ContentContainerTitle>
              {vaccineFragments?.[3]}
            </ContentContainerTitle>
            <ContentContainerInformation>
              {composition}
            </ContentContainerInformation>
          </ContentContainer>
          <ContentContainer borderBottom>
            <ContentContainerTitle h4MaxWidthDesktop="9rem">
              {vaccineFragments?.[4]}
            </ContentContainerTitle>
            <ContentContainerInformation>
              {whoShouldTake}
            </ContentContainerInformation>
          </ContentContainer>
          <ContentContainer borderBottom>
            <ContentContainerTitle h4MaxWidthDesktop="14rem">
              {vaccineFragments?.[5]}
            </ContentContainerTitle>
            <ContentContainerInformation>
              {howMuchDose}
            </ContentContainerInformation>
          </ContentContainer>
          <ContentContainer borderBottom>
            <ContentContainerTitle>
              {vaccineFragments?.[6]}
            </ContentContainerTitle>
            <ContentContainerInformation>
              {contraindication}
            </ContentContainerInformation>
          </ContentContainer>
          {formattedRouteOfApplication &&
            formattedRouteOfApplication.length > 0 && (
              <ContentContainer borderBottom>
                <ContentContainerTitle>
                  {vaccineFragments?.[7]}
                </ContentContainerTitle>
                <ContentContainerInformation>
                  {formattedRouteOfApplication &&
                    formattedRouteOfApplication.join(', ')}
                </ContentContainerInformation>
              </ContentContainer>
            )}

          <PageListGroup
            markdownContent={vaccinesList?.markdown || []}
            pageList={vaccines}
            disableItemBySlug={slug}
          />
        </Content>
      </Container>
    </Layout>
  );
};

export const VaccinesPageQuery = graphql`
  query VaccinePageQuery {
    gcms {
      site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
        pages(locales: pt_BR, where: { id: "cl1w9aftt0xso0blvk4enqv5r" }) {
          fragments(where: { id: "ckskodkpkaqlq0b75tyldjjj9" }) {
            markdown
          }
        }
      }
      fragments(locales: pt_BR, where: { id: "cktbpdb60dejv0c73e2fx9rsd" }) {
        singletexts
        assetpicker(locales: en) {
          handle
          width
          height
        }
      }
      vaccines(locales: pt_BR) {
        name
        active
      }
    }
  }
`;

export default Vaccine;
